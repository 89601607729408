import styled from "styled-components";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import("./slick.css");

export const SliderWrapper = styled.div`
	padding: 0rem 4rem;
	.slick-slider {
		box-shadow: ${({ isBoxShadow }) =>
			isBoxShadow
				? "rgb(0 0 0 / 1%) 0px 78px 31px, rgb(0 0 0 / 2%) 0px 44px 26px, rgb(0 0 0 / 3%) 0px 20px 20px, rgb(0 0 0 / 4%) 0px 5px 11px, rgb(0 0 0 / 4%) 0px 0px 0px"
				: "none"};
		border-radius: 0.5rem;
	}
	.slick-slide {
		& img {
			display: inherit;
		}
	}
	.slick-dots {
		width: 100%;
		bottom: -40px;
	}
	.slick-dots li {
		margin: 5px;
		width: 8px;
		height: 8px;
	}
	.slick-dots li button {
		width: 8px;
		height: 8px;
		&:not(.slick-arrow) {
			border-radius: 50%;
		}
		&:focus {
			outline: 2px solid var(--secondary-blue-300);
			outline-offset: 4px;
		}
	}
	.slick-dots li.slick-active button:before {
		color: ${(props) =>
			`${props.dotsColor ? props.dotsColor : "var(--primary-forest-400)"}`};
	}
	.slick-dots li button:before {
		font-size: 15px;
		color: var(--primary-forest-400);
	}
	button.slick-prev {
		position: absolute;
		width: 3rem;
		height: 3rem;
		cursor: pointer;
		border: 1px solid
			${(props) =>
				`${
					props.darkBg
						? " var(--primary-neutral-white)"
						: "var(--primary-neutral-nightshade-800)"
				}`};
		left: -67px;
		background-image: ${(props) =>
			`${
				props.darkBg
					? "url(https://res.cloudinary.com/springboard-images/image/upload/v1672984616/website-redesign-dev/Icon/16px/white_prev_arrow.svg)"
					: "url(https://res.cloudinary.com/springboard-images/image/upload/v1672984616/website-redesign-dev/Icon/16px/prev_arrow.svg)"
			}`};
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 50%;
		&:before {
			content: "";
		}
	}
	button.slick-next {
		position: absolute;
		width: 3rem;
		height: 3rem;
		cursor: pointer;
		border: 1px solid
			${(props) =>
				`${
					props.darkBg
						? " var(--primary-neutral-white)"
						: "var(--primary-neutral-nightshade-800)"
				}`};
		right: -67px;
		background-image: ${(props) =>
			`${
				props.darkBg
					? "url(https://res.cloudinary.com/springboard-images/image/upload/v1675689621/website-redesign-dev/Icon/16px/white_next_arrow.svg)"
					: "url(https://res.cloudinary.com/springboard-images/image/upload/v1672984616/website-redesign-dev/Icon/16px/next_arrow.svg)"
			}`};
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 50%;
		&:before {
			content: "";
		}
	}
	.arrow-next {
		display: none;
	}
	.arrow-prev {
		display: none;
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 0rem;
		.slick-arrow.arrow-next {
			display: block;
			right: -40px;
			top: 17px;
			width: 2rem;
			height: 2rem;
		}
		.slick-dots li button.custom-arrow:before {
			opacity: 0;
		}
		.slick-arrow.arrow-prev {
			display: block;
			left: -28px;
			top: 17px;
			width: 2rem;
			height: 2rem;
		}
	}
`;
