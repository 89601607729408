import React, { useRef, useEffect } from "react";
import Proptypes from "prop-types";
import Slick from "react-slick";
import { SliderWrapper } from "./styles.js";

const Slider = ({
	carouselData = [],
	otherSettings = {},
	responsive = [],
	isBoxShadow,
	className,
	reference,
	darkBg,
	...restProps
}) => {
	const sliderRef = useRef();
	const selectedRef = reference ? reference : sliderRef;
	const gotoNext = () => {
		selectedRef.current.slickNext();
	};
	const goToPrev = () => {
		selectedRef.current.slickPrev();
	};
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		arrows: true,
		responsive: [
			{
				breakpoint: 990,
				settings: {
					dots: true,
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: false,
					speed: 500,
					arrows: false,
					appendDots: (dots) => {
						return (
							<ul>
								{/** This is adding custom previous and next button for the mobile view. For desktop inbuilt next/prev button is used.*/}
								<li>
									<button
										onClick={goToPrev}
										className="slick-arrow slick-prev arrow-prev custom-arrow"
									>
										Previous
									</button>
								</li>
								{dots}
								<li>
									<button
										onClick={gotoNext}
										className="slick-arrow slick-next arrow-next custom-arrow"
									>
										Next
									</button>
								</li>
							</ul>
						);
					},
				},
			},
			...responsive,
		],
		...otherSettings,
		...restProps,
	};
	return (
		<>
			<SliderWrapper
				darkBg={darkBg}
				isBoxShadow={isBoxShadow}
				dotsColor={settings?.dotsColor}
				className={`slider ${className}`}
			>
				<Slick ref={selectedRef} {...settings}>
					{carouselData}
				</Slick>
			</SliderWrapper>
		</>
	);
};

Slider.propTypes = {
	carouselData: Proptypes.array,
	otherSettings: Proptypes.object,
	isBoxShadow: Proptypes.bool,
};

Slider.defaultProps = {
	carouselData: [],
	otherSettings: {},
	isBoxShadow: false,
};

export default Slider;
